import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import TrendArrow from './VehicleDataTable/TrendArrow'
import style from './ParameterInfoModal.scss'
import i18n from '../util/localization'
import { ServicePlaningWrenchIcon } from './VehicleDataTable/ServicePlanningIcon'

type itemInfo = [number, string, string, React.ReactFragment?]

const getParameterInfo = (): itemInfo[] => {
  const monitoringReportInfo: itemInfo[] = [
    [1, 'TH_StartDate', 'StartVisarNärDeFörstaVärdenaFörPeriodenRapporteradesFrånFordonet_'],
    [2, 'TH_StopDate', 'StoppVisarNärDeSistaVärdenaFörPeriodenRapporteradesFrånFordonet_Tidpunkten'],
    [3, 'TH_TotalOdometer', 'VadFordonetsVägmätareStodPåNärPeriodenAvslutades_'],
    [5, 'TH_DSScore', 'PeriodensGenomsnittspoängFörAllaKategorierSomScaniaFörarstödUtvärderar_GenomsnittspoängenIÖversiktsrapporten'],
    [6, 'TH_Coasting', 'HurMångaProcentAvPeriodensSträckaSomFordonetHarKörtsUtanAtt'],
    [7, 'TH_Idling', 'HurStorDelAvPeriodensMotordrifttidSomFordonetHarKörtsPåTomgång_'],
    [8, 'TH_OverSpeeding', 'HurMångaProcentAvPeriodensMotordrifttidSomFordonetHarKörtSnabbareÄn'],
    [9, 'TH_HarshBraking', 'HurMångaKraftigaInbromsningarSomHarGjortsIGenomsnittPer100Km'],
    [13, 'Trender', 'TrendpilarVisarOmVärdetÖkatEllerMinskatSedanFöregåendePeriod_TrendpilarnasFärg', <><TrendArrow direction="down" /><TrendArrow direction="up" positive /></>]
  ]
  const monitoringAndEnvironmentalReportInfo: itemInfo[] = [
    [4, 'TH_Distance', 'DenSammanlagdaSträckaSomFordonetHarKörtsUnderPerioden_'],
    [10, 'TH_FuelConsumption', 'FordonetsBeräknadeBränsleförbrukning1_'],
    [11, 'Energiförbrukning', 'FordonetsBeräknadeEnergiförbrukning_'],
    [12, 'TH_CO2Total', 'FordonetsBeräknadeKoldioxidutsläppUnderPerioden_KoldioxidSomBildasNärFordonetKörs_När']
  ]

  const environmentalReportInfo: itemInfo[] = [
    [14, 'CO', 'MouseOverCO'],
    [15, 'NOx', 'MouseOverNOochNO2'],
    [16, 'PM', 'MouseOverPM'],
    [17, 'HC', 'MouseOverHC']

  ]

  const includedItems = ENV_VAR_APPLICATION === 'MONITORING' ? monitoringReportInfo : environmentalReportInfo

  return [...includedItems, ...monitoringAndEnvironmentalReportInfo].sort((a, b) => a[0] - b[0])
}

const formatParameterInfo = (parameterInfo: itemInfo[]) => parameterInfo.map(item => (
  <React.Fragment key={item[0]}>
    <p className={style['parameter-name']}>{i18n.t(item[1])}</p>
    <p className={style['parameter-description']}>{i18n.t([item[2]])}{item[3]}</p>
  </React.Fragment>
))

const ParameterInfoModal = ({ visible, onClose, onChangeTab }) => (
  <div>
    <Modal
      style={{ zIndex: 2000 }}
      dialogClassName={style['parameter-info-modal']}
      keyboard
      bsSize="large"
      show={visible.box}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>&nbsp;</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={style.title}>{i18n.t('Hjälp')}</p>
        <div id="help_menu_bar" className={style.help_menu_bar}>
          <span onClick={() => { onChangeTab({ ...visible, setting: false, table: true }) }} className={visible.table ? 'active-help-menu' : ''}>{i18n.t('SåTolkarDuTabellen')}</span>
          <span onClick={() => { onChangeTab({ ...visible, setting: true, table: false }) }} className={visible.setting ? 'active-help-menu' : ''}>{i18n.t('Inställningar')}</span>
        </div>
        { visible.setting && (
        <div className="setting_guide with_padding">
          <p className={style['parameter-name']}>{i18n.t('MinPersonalprofil')}</p>
          <p className={style['parameter-description']}>{i18n.t('UnderMinProfilKanDu_')}</p>
          <p className={style['parameter-name']}>{i18n.t('E_Postmottagare')}</p>
          <p className={style['parameter-description']}>{i18n.t('UnderE_PostmottagareKanDu_')}.</p>
        </div>
        )}
        { visible.table && (
        <div className="read_table_guide  with_padding">
          {formatParameterInfo(getParameterInfo())}
          {ENV_VAR_APPLICATION === 'MONITORING' &&
              <Fragment>
                <p className={style['parameter-name']}>{i18n.t('Servicebehov')}</p>
                <p className={style['parameter-description']}>{i18n.t('OmEttFordonBehöverService_')}</p>
                <p className={style['parameter-description_less_padding']}><ServicePlaningWrenchIcon status="Late" /> {i18n.t('Försenad_EnServiceaktivitet_')}</p>
                <p className={style['parameter-description_less_padding']}><ServicePlaningWrenchIcon status="Due" /> {i18n.t('Aktuell_EnServiceaktivitet_')}</p>
                <p className={style['parameter-description_less_padding']}><ServicePlaningWrenchIcon status="Booked" /> {i18n.t('Bokat_EttServicetillfälle_')}</p>
                <p className={style['parameter-description_less_padding']}><ServicePlaningWrenchIcon status="Preliminary" /> {i18n.t('Preliminärt_EttServicetillfälle_')}</p>
              </Fragment>
          }
        </div>
        )
        }
      </Modal.Body>
    </Modal>
  </div>
)

ParameterInfoModal.propTypes = {
  visible: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired
}

export default ParameterInfoModal
