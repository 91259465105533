/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useFlags } from 'launchdarkly-react-client-sdk'

import CycledEnergyPopup from './CycledEnergyPopup'
import YNTPopup from './YNTPopup'
import VehicleDataColumn from './VehicleDataColumn'
import VehicleNameColumn from './VehicleNameColumn'
import VehicleDetails from './VehicleDetails'
import style from './VehicleDataTableRow.scss'
import filterServicePlaningData from '../../state/servicePlaning/logic'
import { converttoTime } from '../../util/dataManipulation'

const NO_DATA_VALUE = '-'

const controlValue = (value, col) => (col.showAsTime ? converttoTime(value) : value)

const VehicleDataTableRow = ({
  item, propulsionType, previousPeriod, units, servicePlans, userUnit, reportColumns, ReactGA, componentId
}) => {
  const notUsedVehicle = ENV_VAR_APPLICATION === 'MONITORING' && !item.info.periodStart && !item.info.periodStop
  const vehicleName = item.info.name != null && item.info.name.trim() !== '' ? item.info.name : NO_DATA_VALUE
  const emissionSpecDetails = item.info.emissionSpecDetails || null

  const servicePlansProcessed = filterServicePlaningData(servicePlans, item.info.externalEquipmentReference, userUnit)
  const cycledEnergyDetails = {
    externalEquipmentReference: item.info.externalEquipmentReference,
    vehicleName
  }

  const { showYntProposals } = useFlags()

  return (
    <tr className={notUsedVehicle ? 'disabled' : ''} id={componentId}>
      <td>
        <div className={`${style.vehicleName} hidden-xs hidden-sm`} style={{ display: 'flex' }}>
          { servicePlansProcessed.status
            ? (
            <VehicleNameColumn
              vehicleName={vehicleName}
              servicePlans={servicePlansProcessed}
              externalEquipmentReference={item.info.externalEquipmentReference}
              ReactGA={ReactGA}
            />
              )
            : <div className="secret_text">{vehicleName}</div>
          }
          <div className={`${style.iconsContainer}`}>
        {['electric', 'hybrid'].includes(propulsionType) && <CycledEnergyPopup details={cycledEnergyDetails} ReactGA={ReactGA}/>}
        {(ENV_VAR_APPLICATION === 'MONITORING' && item.info.yntDetail && item.info.yntDetail?.yntId) && showYntProposals && <YNTPopup details={item.info} ReactGA={ReactGA}></YNTPopup> }
          </div>
        </div>
        <div className="visible-xs-block visible-sm-block">
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <VehicleDetails
            name={vehicleName}
            vehicle={item}
            units={units}
            columns={reportColumns}
            previousPeriod={previousPeriod}
            servicePlans={servicePlansProcessed}
            externalEquipmentReference={item.info.externalEquipmentReference}
            ReactGA={ReactGA}
            />
          {(ENV_VAR_APPLICATION === 'MONITORING' && item.info.yntDetail && item.info.yntDetail?.yntId) && showYntProposals && <YNTPopup details={item.info} ReactGA={ReactGA}></YNTPopup> }
            </div>
        </div>
      </td>
      { ENV_VAR_APPLICATION !== 'ENVIRONMENTAL' && <td className="collapsable-md">{item.info.periodStart ? moment(item.info.periodStart).format('lll') : NO_DATA_VALUE}</td> }
      { ENV_VAR_APPLICATION !== 'ENVIRONMENTAL' && <td className="collapsable-md">{item.info.periodStop ? moment(item.info.periodStop).format('lll') : NO_DATA_VALUE}</td> }
      {
         reportColumns.map((col, index) => (
           <td key={col.titleKey + index} className={col.xsScreen ? '' : 'collapsable-sm'}>
             <VehicleDataColumn
               vehicleName={vehicleName}
               parameter={col.titleKey}
               container={this}
               periodValue={item.parameters[col.name] ? controlValue(item.parameters[col.name].value, col) : null}
               referenceValue={col.showTrend ? item.parameters[col.name]?.previous : null}
               delta={col.showTrend ? item.parameters[col.name]?.delta : null}
               isGood={col.showTrend ? item.parameters[col.name]?.isGood : null}
               previousPeriod={col.showTrend ? previousPeriod : null}
               {... col.specCol ? { emissionSpec: emissionSpecDetails } : {}}
               ReactGA={ReactGA}
             />
           </td>
         ))
      }
    </tr>
  )
}

VehicleDataTableRow.propTypes = {
  item: PropTypes.shape({
    info: PropTypes.any,
    parameters: PropTypes.any
  }).isRequired,
  rowIndex: PropTypes.number,
  propulsionType: PropTypes.string.isRequired,
  reportColumns: PropTypes.array.isRequired,
  servicePlans: PropTypes.shape({}).isRequired,
  previousPeriod: PropTypes.string,
  units: PropTypes.shape({}).isRequired,
  userUnit: PropTypes.string.isRequired,
  ReactGA: PropTypes.shape().isRequired,
  componentId: PropTypes.string
}

VehicleDataTableRow.defaultProps = {
  previousPeriod: '/'
}

export default VehicleDataTableRow
