import { useSelector } from 'react-redux'

import userSelectors from '../../state/user/selectors'
import ReportsSchema from './ReportsSchema'

const useReportColumns = (propulsionType: PropulsionType): any[] => {
  const country = useSelector(userSelectors.getCustomerCountry)
  const showUnstableHybridData = true
  const hideAdBlueData = country?.toLowerCase() === 'gb'
  return ReportsSchema.columnsOf(propulsionType, { showUnstableHybridData, hideAdBlueData })
}

const useReportModel = (propulsionType: PropulsionType): ReportsSchemaPropulsionType => {
  const showUnstableHybridData = true
  return ReportsSchema.get(propulsionType, { showUnstableHybridData })
}

const useReportKpi = (propulsionType: PropulsionType): any[] => {
  const report = useReportModel(propulsionType)
  return report.kpi
}

export {
  useReportColumns,
  useReportModel,
  useReportKpi
}
