import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useKeycloak } from '@react-keycloak/web'
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk'

import LaunchContainer from './LaunchContainer'
import ReportContainer from './ReportContainer'
import DefaultReportContainer from './DefaultReportContainer'
import Style from './MainContainer.scss'
import userSelectors from '../state/user/selectors'
import { isUserAuthenticated } from '../state/authentication/selectors'
import { retrieveFederationGatewayID } from '../util/launchDarkly'
const SettingsContainer = React.lazy(() => import('./SettingsContainer'))

const styleMopinionFeedbackButton = (isZendeskWidgetEnabled) => {
  const mopinionFeedbackButton = document.querySelector('button.btn-open-survey')

  if (mopinionFeedbackButton) {
    const { style } = mopinionFeedbackButton
    style.height = '36px'
    style.marginBottom = '13px'
    style.marginRight = isZendeskWidgetEnabled ? '60px' : '20px'
    style.borderRadius = '3px'
    style.display = isZendeskWidgetEnabled ? 'none' : 'flex'
    style.alignItems = 'center'
  }
}

const MainContainer = (props) => {
  const { initialized, keycloak } = useKeycloak()
  const ldClient = useLDClient()
  const [flagsReady, setFlagsReady] = useState(false)
  const { showZendeskWidget } = useFlags()
  const userIsAuthenticated = useSelector(isUserAuthenticated) || false
  const isStaffLoadingSuccess = useSelector(userSelectors.hasLoadedUser)
  const staffName = useSelector(userSelectors.getUserName) || ''
  const externalStaffReference = useSelector(userSelectors.getExternalStaffReference)
  const staffLanguage = useSelector(userSelectors.getLanguage) || 'en-GB'
  const customerName = useSelector(userSelectors.getCustomerName)
  const customerCountry = useSelector(userSelectors.getCustomerCountry)
  const externalCustomerReference = useSelector(userSelectors.getExternalCustomerReference)
  const [widgetFgToken, setWidgetFgToken] = useState(keycloak.token)

  useEffect(() => {
    if (keycloak && initialized && keycloak.token && keycloak.idTokenParsed && isStaffLoadingSuccess) {
      const userData = {
        kind: 'user',
        key: retrieveFederationGatewayID(keycloak.idTokenParsed),
        name: staffName,
        externalStaffReference,
        language: staffLanguage,
        account: keycloak.idTokenParsed.idp_username,
        externalCustomerReference,
        companyName: customerName,
        market: customerCountry
      }

      ldClient?.identify(userData).then(() => {
        setFlagsReady(true)
      })
    }
  }, [initialized, isStaffLoadingSuccess, keycloak, ldClient])

  // To be removed when Mopinion is implemented in Zendesk
  styleMopinionFeedbackButton(showZendeskWidget)

  const handleWidgetFgToken = (e) => {
    setWidgetFgToken(e.detail)
  }

  useEffect(() => {
    window.addEventListener('kcTokenSuccessfullyFetched', handleWidgetFgToken)

    return () => {
      window.removeEventListener('kcTokenSuccessfullyFetched', handleWidgetFgToken)
    }
  }, [handleWidgetFgToken])

  return (
  <section className="dark">
    <div className={`container-fluid ${Style.verticalMargins}`}>
      <div>
        <LaunchContainer />
        <Switch>
          <Route
            path="/:type(week|month|year)/:start(\d{4}-\d{2}-\d{2})/:propulsionType(liquid|gas|electric|engines|hybrid)"
            render={(someProps) => <ReportContainer flagsReady={flagsReady} ReactGA={props.ReactGA} {...someProps} />}
          />
          <Route
            path="/:type(range)/:start(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)/:end(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)/:propulsionType(liquid|gas|electric|engines|hybrid)"
            render={(someProps) => <ReportContainer flagsReady={flagsReady} ReactGA={props.ReactGA} {...someProps} />}
          />
          <Route path="/settings"
            render={(someProps) => <SettingsContainer ReactGA={props.ReactGA} {...someProps} />} />
          <Route
            path="/:type(week|month|year)/:start(\d{4}-\d{2}-\d{2})"
            component={DefaultReportContainer} />
          <Route component={DefaultReportContainer} />
        </Switch>
        {showZendeskWidget && userIsAuthenticated && widgetFgToken?.length > 0 && initialized && <zendesk-widget auth-token={widgetFgToken} user-language={staffLanguage}></zendesk-widget>}
      </div>
    </div>
  </section>)
}

MainContainer.propTypes = {
  ReactGA: PropTypes.shape().isRequired
}

export default MainContainer
